import axios from '@axios'

export default {
  namespaced: true,
  state: {
    departments: [],
  },
  getters: {
    getDepartment(id) {
      const result = this.departments.find(e => e.id === id)
      return result ? result.name : 'Dept. N/A'
    },
  },
  mutations: {
    UPDATE_DEPARTMENTS(state, val) {
      state.departments = val
    },
    UPDATE_DEPARTMENT(state, val) {
      const index = state.departments.indexWhere(e => e.id === val.id)
      if (index !== -1) {
        state.departments[index] = val
      } else {
        state.departments.push(val)
      }
    },
  },
  actions: {
    fetchDepartments(context) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/departments')
          .then(response => {
            context.commit('UPDATE_DEPARTMENTS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchDepartment(context, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/departments/${id}`)
          .then(response => {
            context.commit('UPDATE_DEPARTMENT', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addDepartment(context, jobData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/departments', { job: jobData })
          .then(response => {
            context.commit('UPDATE_DEPARTMENT', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
