import { Ability } from '@casl/ability'

import jwt from '@/utils/jwt'
import keys from '@/utils/keys'

import { defineAbilities, initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const token = localStorage.getItem('token')

let user = null

if (token) {
  try {
    const jwtPayload = jwt.verify(
      keys.public_key(),
      // eslint-disable-next-line comma-dangle
      token,
    )
    user = jwtPayload.user
  } catch (e) {
    console.error('Token Error: Please login again to authenticate.')
    localStorage.setItem('a', 'se')
    localStorage.setItem('se', e.message)
  }
}

const existingAbility = user ? defineAbilities(user) : null

export default new Ability(existingAbility || initialAbility)
