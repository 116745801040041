/* eslint-disable operator-linebreak */
import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import axios from '@/libs/axios'

import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'Auth',
      },
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: () => import('@/views/jobs/Jobs.vue'),
      meta: {
        pageTitle: 'Jobs',
        breadcrumb: [
          {
            text: 'Jobs',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'Job',
        action: 'read',
      },
    },
    {
      path: '/jobs/:id/details',
      name: 'job-details',
      component: () => import('@/views/jobs/JobDetails.vue'),
      meta: {
        pageTitle: 'Job Details',
        breadcrumb: [
          {
            text: 'Jobs',
            url: 'jobs',
          },
          {
            text: 'Job Details',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'Job',
        action: 'read',
      },
    },
    {
      path: '/jobs/:id/dashboard',
      name: 'job-dashboard',
      component: () => import('@/views/jobs/Dashboard.vue'),
      meta: {
        pageTitle: 'Job Dashboard',
        breadcrumb: [
          {
            text: 'Jobs',
            url: 'jobs',
          },
          {
            text: 'Job Dashboard',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'Job',
        action: 'manage',
      },
    },
    {
      path: '/jobs/new',
      name: 'new-job',
      component: () => import('@/views/jobs/Job.vue'),
      meta: {
        pageTitle: 'New Job',
        breadcrumb: [
          {
            text: 'Jobs',
            url: 'jobs',
          },
          {
            text: 'New Job',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'Job',
        action: 'create',
      },
    },
    {
      path: '/jobs/:id/edit',
      name: 'job-edit',
      component: () => import('@/views/jobs/Job.vue'),
      meta: {
        pageTitle: 'Edit Job',
        breadcrumb: [
          {
            text: 'Jobs',
            url: 'jobs',
          },
          {
            text: 'Edit Job',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'Job',
        action: 'update',
      },
    },
    {
      path: '/jobs/:id/apply',
      name: 'job-application',
      component: () => import('@/views/jobs/ApplyJob.vue'),
      meta: {
        pageTitle: 'Job Application',
        breadcrumb: [
          {
            text: 'Jobs',
            url: '/jobs',
          },
          {
            text: 'Job Details',
            url: '/jobs/:id',
          },
          {
            text: 'Job Application',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'UserApplication',
        action: 'create',
      },
    },
    {
      path: '/jobs/:id/submissions',
      name: 'job-submissions',

      component: () => import('@/views/jobs/components/JobSubmissions.vue'),
      meta: {
        pageTitle: 'Job Submissions',
        breadcrumb: [
          {
            text: 'Jobs',
            url: '/jobs',
          },
          {
            text: 'Job Submissions',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'JobSubmission',
        action: 'manage',
      },
    },
    {
      path: '/jobs/:id/shortlist',
      name: 'job-shortlist',
      component: () => import('@/views/jobs/Shortlist.vue'),
      meta: {
        pageTitle: 'Job Shortlist',
        breadcrumb: [
          {
            text: 'Jobs',
            url: 'jobs',
          },
          {
            text: 'Job Shortlist',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'JobSubmission',
        action: 'manage',
      },
    },
    {
      path: '/jobs/my-applications',
      name: 'my-applications',
      component: () => import('@/views/jobs/MyApplications.vue'),
      meta: {
        pageTitle: 'My Applications',
        breadcrumb: [
          {
            text: 'Jobs',
            active: false,
          },
          {
            text: 'My Applications',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'UserApplication',
        action: 'read',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'UserProfile',
        action: 'manage',
      },
    },
    {
      path: '/profile/update',
      name: 'update-profile',
      component: () => import('@/views/profile/ProfileSetting.vue'),
      meta: {
        pageTitle: 'Update Profile',
        breadcrumb: [
          {
            text: 'Profile',
            url: 'profile',
          },
          {
            text: 'Update Profile',
            active: true,
          },
        ],
        authRequired: true,
        resource: 'UserProfile',
        action: 'manage',
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
        authRequired: true,
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
      meta: {
        resource: 'Auth',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/coming-soon',
      name: 'coming-soon',
      component: () => import('@/views/misc/ComingSoon.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// Check if a user is logged in before proceeding
router.beforeEach(async (to, _, next) => {
  await store.restored
  // Check if page has authRequired meta tag
  // Allow access to page if authRequired is null or false
  // Else redirect to login page
  if (to.meta.authRequired === true) {
    // Get authentication status
    const authStatus =
      store.state.auth.user !== null &&
      store.state.auth.access_token !== null &&
      store.state.auth.refresh_token !== null &&
      store.state.auth.state !== null
    if (authStatus) {
      if (!('Authorization' in axios.defaults.headers.common)) {
        axios.defaults.headers.common.Authorization = `Bearer ${store.state.auth.access_token}`
      }
      if (!canNavigate(to)) {
        if (to.name === 'login') {
          return next({
            name: 'login',
          })
        }
        return next({ name: 'not-authorized' })
      }
      return next()
    }
    return next({
      name: 'login',
      query: {
        to: to.path,
      },
    })
  }
  return next()
})

export default router
