import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './auth'
import jobs from './job'
import departments from './department'

const vuexLocal = new VuexPersistence({
  // TODO: Persist data in indexedDB for more security and space
  storage: localForage,
  asyncStorage: true,
})
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    auth,
    jobs,
    departments,
  },
  plugins: [vuexLocal.plugin],
  strict: process.env.DEV,
})
