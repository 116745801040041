/* eslint-disable indent */
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    user: null,
    userInformation: null,
    userEducation: null,
    userExperience: null,
    userReferees: null,
    userDocuments: null,
    token: null,
    access_token: null,
    refresh_token: null,
  },
  getters: {
    currentUser: state => state.user,
  },
  mutations: {
    UPDATE_AUTH_USER(state, val) {
      if (val) {
        state.user = val
      }
    },
    UPDATE_AUTH_USER_INFORMATION(state, val) {
      if (val) {
        state.userInformation = val
      }
    },
    UPDATE_USER_EDUCATION(state, val) {
      if (val) {
        state.userEducation = val
      }
    },
    UPDATE_USER_EXPERIENCE(state, val) {
      if (val) {
        state.userExperience = val
      }
    },
    UPDATE_USER_REFEREES(state, val) {
      if (val) {
        state.userReferees = val
      }
    },
    UPDATE_USER_REFEREE(state, val) {
      if (val) {
        const index = state.userReferees.findIndex(e => e.id === val.id)
        if (index === -1) {
          state.userReferees.push(val)
        } else {
          state.userReferees[index] = val
        }
      }
    },
    UPDATE_USER_DOCUMENTS(state, val) {
      if (val) {
        state.userDocuments = val
      }
    },
    UPDATE_USER_DOCUMENT(state, val) {
      if (val) {
        const index = state.userDocuments.findIndex(e => e.id === val.id)
        if (index === -1) {
          state.userDocuments.push(val)
        } else {
          state.userDocuments[index] = val
        }
      }
    },
    UPDATE_USER_DOCUMENT_DESCRIPTION(state, payload) {
      if (payload) {
        const index = state.userDocuments
          ? state.userDocuments.findIndex(
              // eslint-disable-next-line comma-dangle
              e => e.id === payload.id,
            )
          : -1
        if (index !== -1) {
          state.userDocuments[index].description = payload.description
        }
      }
    },
    REMOVE_USER_DOCUMENT(state, id) {
      if (id) {
        const index = state.userDocuments.findIndex(e => e.id === id)
        if (index !== -1) {
          state.userDocuments.splice(index, 1)
        }
      }
    },
    UPDATE_JWT_TOKEN(state, val) {
      state.token = val
    },
    UPDATE_ACCESS_TOKEN(state, val) {
      state.access_token = val.access_token
      state.refresh_token = val.refresh_token
    },
    LOGOUT(state) {
      state.user = null
      state.userInformation = null
      state.userEducation = null
      state.userExperience = null
      state.userReferees = null
      state.userDocuments = null
      state.token = null
      state.access_token = null
      state.refresh_token = null
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    },
    REMOVE_BEARER() {
      delete axios.defaults.headers.common.Authorization
    },
  },
  actions: {
    login(context, payload) {
      const data = payload
      delete data.remember_me
      return axios({
        method: 'POST',
        url: 'v1/auth/login',
        headers: {},
        data,
      }).then(res => res)
    },
    registerUser(context, payload) {
      return axios({
        method: 'POST',
        url: 'v1/auth/register',
        headers: {},
        data: payload,
      }).then(res => res)
    },
    getUserPersonalInfo(context) {
      return axios({
        method: 'GET',
        url: 'v1/auth/personal-info',
        headers: {},
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_AUTH_USER', res.data.data)
        }
      })
    },
    getUserInformation(context) {
      return axios({
        method: 'GET',
        url: 'v1/auth/information',
        headers: {},
      })
        .then(res => {
          context.commit('UPDATE_AUTH_USER_INFORMATION', res.data.data)
          context.commit('UPDATE_AUTH_USER', res.data.data.uid)
        })
        .catch(err => {
          throw err
        })
    },
    updateUserInfo(context, payload) {
      return axios({
        method: 'PATCH',
        url: 'v1/auth/personal-info',
        headers: {},
        data: payload,
      })
    },
    updateUserPassword(context, payload) {
      return axios({
        method: 'PUT',
        url: 'v1/auth/password',
        headers: {},
        data: payload,
      })
    },
    updateUserInformation(context, payload) {
      return axios({
        method: 'PUT',
        url: 'v1/auth/information',
        headers: {},
        data: payload,
      })
    },
    updateUserEducation(context, payload) {
      return axios({
        method: 'PUT',
        url: `v1/user/${context.state.user.id}/education`,
        headers: {},
        data: payload,
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_EDUCATION', res.data.data)
        }
        return res.data.data
      })
    },
    getUserEducation(context) {
      return axios({
        method: 'GET',
        url: `v1/user/${context.state.user.id}/education`,
        headers: {},
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_EDUCATION', res.data.data)
        }
      })
    },
    getUserExperience(context) {
      return axios({
        method: 'GET',
        url: `v1/user/${context.state.user.id}/experience`,
        headers: {},
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_EXPERIENCE', res.data.data)
        }
      })
    },
    updateUserExperience(context, payload) {
      return axios({
        method: 'PUT',
        url: `v1/user/${context.state.user.id}/experience`,
        headers: {},
        data: payload,
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_EXPERIENCE', res.data.data)
        }
        return res
      })
    },
    getUserReferees(context) {
      return axios({
        method: 'GET',
        url: `v1/user/${context.state.user.id}/referees`,
        headers: {},
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_REFEREES', res.data.data)
        }
      })
    },
    updateUserReferees(context, payload) {
      return axios({
        method: 'PUT',
        url: `v1/user/${context.state.user.id}/referees`,
        headers: {},
        data: payload,
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_REFEREES', res.data.data)
        }
        return res
      })
    },
    getUserDocuments(context) {
      return axios({
        method: 'GET',
        url: `v1/user/${context.state.user.id}/documents`,
        headers: {},
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_DOCUMENTS', res.data.data)
        }
      })
    },
    uploadUserDocument(context, payload) {
      return axios({
        method: 'POST',
        url: `v1/user/${context.state.user.id}/documents`,
        headers: {},
        data: payload,
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_DOCUMENT', res.data.data)
        }
        return res
      })
    },
    deleteUserDocument(context, payload) {
      return axios({
        method: 'DELETE',
        url: `v1/user/${context.state.user.id}/documents/${payload.id}`,
        headers: {},
        data: payload,
      }).then(res => {
        if (res.status === 200) {
          context.commit('REMOVE_USER_DOCUMENT', payload.id)
        }
        return res
      })
    },
    updateUserDocumentDescription(context, payload) {
      return axios({
        method: 'PUT',
        url: `v1/user/${context.state.user.id}/documents/${payload.id}/description`,
        headers: {},
        data: payload,
      }).then(res => {
        if (res.status === 200) {
          context.commit('UPDATE_USER_DOCUMENT_DESCRIPTION', res.data.data)
        }
        return res
      })
    },
  },
}
