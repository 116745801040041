export const PERMISSIONS = {
  MANAGE: 'manage',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
}

export const defineAbilities = user => {
  let ability = [
    {
      subject: 'Auth',
      action: PERMISSIONS.READ,
    },
  ]

  switch (user.role) {
    case 'Admin':
      ability = ability.concat([
        {
          action: PERMISSIONS.MANAGE,
          subject: 'JobSubmission',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'Department',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'Job',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'Log',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'User',
        },
        {
          subject: 'UserProfile',
          action: PERMISSIONS.MANAGE,
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserDetails',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserDocument',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserEducation',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserExperience',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserReferees',
        },
      ])
      break
    case 'Staff':
      ability = ability.concat([
        {
          action: PERMISSIONS.MANAGE,
          subject: 'JobSubmission',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'Department',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'Job',
        },
        {
          action: PERMISSIONS.READ,
          subject: 'Log',
        },
      ])
      // can('manage', 'Department')
      // can('manage', 'Job')
      // can('read', 'Log')
      // can('manage', 'User')
      // can('manage', 'UserApplication')
      // can('manage', 'UserDetails')
      // can('manage', 'UserDocument')
      // can('manage', 'UserEducation')
      // can('manage', 'UserExperience')
      // can('manage', 'UserReferees')

      // cannot('delete', 'User')
      // cannot('delete', 'UserApplication')
      // cannot('delete', 'UserDetails')
      // cannot('delete', 'UserDocument')
      // cannot('delete', 'UserEducation')
      // cannot('delete', 'UserExperience')
      // cannot('delete', 'UserReferees')
      break
    case 'User':
      ability = ability.concat([
        {
          subject: 'UserProfile',
          action: PERMISSIONS.MANAGE,
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserDetails',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserDocument',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserEducation',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserExperience',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserReferees',
        },
        {
          action: PERMISSIONS.MANAGE,
          subject: 'UserApplication',
        },

        {
          action: PERMISSIONS.READ,
          subject: 'Job',
        },
        {
          action: PERMISSIONS.READ,
          subject: 'Department',
        },
        {
          action: PERMISSIONS.READ,
          subject: 'Log',
        },
      ])
      break
    default:
      ability = ability.concat([
        {
          action: PERMISSIONS.READ,
          subject: 'Department',
        },
        {
          action: PERMISSIONS.READ,
          subject: 'Log',
        },
      ])
      break
  }

  return ability
}

export const initialAbility = defineAbilities({ role: 'Public' })

export const _ = undefined
