const jwt = require('jsonwebtoken')
const config = require('@/config/config')

module.exports = {
  /**
   * Verify jwt and return jwt data
   * @param {string} publicKey Public key generated from signing private key
   * @param {string} token JWT Token string to be verified
   * @returns JWT Error or data embedded in jwt token
   */
  verify(publicKey, token) {
    return jwt.verify(token, publicKey, {
      issuer: config.auth.accessTokenIssuer,
      algorithms: ['RS256'],
    })
  },
}
