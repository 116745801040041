/* eslint-disable global-require */
let backendHost = 'psb-api.sutholo.com'

if (process.env.NODE_ENV === 'development') {
  const ip = require('./ip').address()
  // const ip = '192.168.1.5'
  backendHost = `${ip}:6020`
}

module.exports = {
  auth: {
    accessTokenIssuer:
      process.env.VUE_ACCESS_TOKEN_ISSUER || 'https://psb.sutholo.com',
  },
  mode: process.env.NODE_ENV,

  backendHost,
  socketURL: `//${backendHost}`,
  apiUrl:
    process.env.NODE_ENV === 'development'
      ? `http://${backendHost}/api`
      : `https://${backendHost}/api`,
}
