module.exports = {
  public_key() {
    return `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6J6idFpTfyaG4HTQhDQy
vQJ99catURGDifdfHpsHGaRpvbsip5fVp8WM2lVefI73jz2O6Csp15W5ONWzsdbb
ppqaUCtef94MvmC/bw8nH/cY/lT40Lw20sNO13swZX3PKj/L3Ga31W0Sj2HYgEAW
Cdtuh7LS9iKE+1ZrjJ24+IQplOw3xN2O4QqGmjharUQdE5qoR5pvRi9UTSxRjaui
o9P/VsORA3qLiPSuNx/k/TRq0f7suoiGlls7fA8rtMCcNm7e2RTHYGyMl5+FDarn
GUkzJY/3AN2F/Aa127PnMYictwQblSCL9Eal+qrGDiFCX6EW0DexIUd36v1Ir3Sq
9wIDAQAB
-----END PUBLIC KEY-----`
  },
}
