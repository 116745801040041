import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'

import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'

import config from './config/config'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

import 'element-ui/lib/theme-chalk/index.css'
import 'element-tiptap/lib/index.css'

Vue.use(VueSocketIOExt, io(config.socketURL), {
  store,
})

Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin, {
  /* plugin options */
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
