import Vue from 'vue'

// axios
import axios from 'axios'
import config from '@/config/config'

const axiosIns = axios.create({
  baseURL: config.apiUrl,
  timeout: 10000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
