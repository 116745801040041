import axios from '@axios'

export default {
  namespaced: true,
  state: {
    jobs: [],
    jobsApplied: [],
    jobsSubmissions: {},
    jobsShortlist: {},
    jobsSubmissionsData: {},
  },
  getters: {
    getJobSubmissions: state => state.jobSubmissions,
    getJobs: state => state.jobs,
  },
  mutations: {
    UPDATE_JOBS(state, val) {
      if (val) {
        state.jobs = val
      }
    },
    SOCKET_UPDATE_JOB(state, val) {
      if (val) {
        const index = state.jobs.findIndex(e => e.id === val.id)
        if (index === -1) {
          state.jobs.push(val)
        } else {
          state.jobs[index] = val
        }
      }
    },
    UPDATE_JOB(state, val) {
      if (val) {
        const index = state.jobs.findIndex(e => e.id === val.id)
        if (index === -1) {
          state.jobs.push(val)
        } else {
          state.jobs[index] = val
        }
      }
    },
    UPDATE_APPLIED_JOBS(state, val) {
      if (val) {
        state.jobsApplied = val
      }
    },
    APPLIED_JOB(state, val) {
      if (val) {
        state.jobsApplied.push(val)
      }
    },
    SUBMITTED_JOBS(state, val) {
      if (val) {
        state.jobsSubmissions[val.id] = val.submissions
      }
    },
    SHORTLISTED_APPLICANTS(state, val) {
      if (val) {
        state.jobsShortlist[val.id] = val.s
      }
    },
    SUBMITTED_JOBS_DATA(state, val) {
      if (val) {
        if (!(`${val.id}` in state.jobsSubmissionsData)) {
          state.jobsSubmissionsData[val.id] = {}
        } else {
          state.jobsSubmissionsData[val.id][val.uid] = val.submissions
        }
      }
    },
    SHORTLIST_APPLICANT(state, val) {
      if (val) {
        if (!(`${val.jid}` in state.jobsShortlist)) {
          state.jobsShortlist[val.jid] = {}
        }
        if (!(`${val.uid}` in state.jobsShortlist[val.jid])) {
          state.jobsShortlist[val.jid][val.uid] = {}
        }
        state.jobsShortlist[val.jid][val.uid] = val
        state.jobsShortlist[val.jid][val.uid].processed = true
        state.jobsShortlist[val.jid][val.uid].shortlisted = true

        const index = state.jobsSubmissions[val.jid].findIndex(
          e => e.uid.id === val.uid,
        )
        if (index !== -1) {
          state.jobsSubmissions[val.jid][index].processed = true
          state.jobsSubmissions[val.jid][index].shortlisted = true
          state.jobsSubmissions[val.jid][index].remarks = val.remarks
        }
      }
    },
    REJECT_APPLICANT(state, val) {
      if (val) {
        const index = state.jobsSubmissions[val.jid].findIndex(
          e => e.uid.id === val.uid,
        )
        if (index !== -1) {
          state.jobsSubmissions[val.jid][index].processed = true
          state.jobsSubmissions[val.jid][index].shortlisted = false
          state.jobsSubmissions[val.jid][index].remarks = val.remarks
        }
      }
    },
  },
  actions: {
    socket_chatMessage() {
      console.log('this action will be called')
    },
    fetchJobs(context, query) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/jobs', { params: query })
          .then(response => {
            context.commit('UPDATE_JOBS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchJob(context, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/jobs/${id}`)
          .then(response => {
            if (response.status === 200) {
              context.commit('UPDATE_JOB', response.data.data)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addJob(context, jobData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/jobs', { ...jobData })
          .then(response => {
            if (response.status === 200) {
              context.commit('UPDATE_JOB', response.data.data)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateJob(context, jobData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/jobs/${jobData.id}`, { ...jobData.job })
          .then(response => {
            if (response.status === 200) {
              context.commit('UPDATE_JOB', response.data.data)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    applyJob(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/jobs/${payload.id}/apply`, {
            cover: payload.cover,
            documents: payload.documents,
            snapshot: payload.snapshot,
          })
          .then(response => {
            if (response.status === 200) {
              context.commit('APPLIED_JOB', response.data.data)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAppliedJobs(context) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/jobs/applied')
          .then(response => {
            context.commit('UPDATE_APPLIED_JOBS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchJobSubmissions(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/jobs/${payload.id}/submissions`)
          .then(response => {
            if (response.status === 200) {
              context.commit('SUBMITTED_JOBS', {
                id: payload.id,
                submissions: response.data.data,
              })
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchJobShortlisted(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/jobs/${payload.id}/shortlist`)
          .then(response => {
            if (response.status === 200) {
              context.commit('SHORTLISTED_APPLICANTS', {
                id: payload.id,
                s: response.data.data,
              })
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchJobSubmissionsData(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/jobs/${payload.id}/submissions-data/${payload.uid}`)
          .then(response => {
            if (response.status === 200) {
              context.commit('SUBMITTED_JOBS_DATA', {
                id: payload.id,
                uid: payload.uid,
                submissions: response.data.data,
              })
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    shortlistApplicant(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/jobs/${payload.jid}/shortlist-applicant/${payload.uaid}`, {
            remarks: payload.remarks,
          })
          .then(response => {
            if (response.status === 200) {
              context.commit('SHORTLIST_APPLICANT', {
                ...response.data.data,
                remarks: payload.remarks,
              })
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    rejectApplicant(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/jobs/${payload.jid}/reject-applicant/${payload.uaid}`, {
            remarks: payload.remarks,
          })
          .then(response => {
            if (response.status === 200) {
              context.commit('REJECT_APPLICANT', {
                ...response.data.data,
                remarks: payload.remarks,
              })
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
